import { Controller } from "@hotwired/stimulus"
import { enableElems, disableElems, showElems, hideElems, validateForm, encodeToBase64, checkAuthState } from "../helpers.js"
import * as linkify from "linkifyjs"
import linkifyElement from "linkify-element"
import { unified } from "unified"
import remarkParse from "remark-parse"
import remarkGfm from "remark-gfm"
import remarkRehype from "remark-rehype"
import rehypeSanitize from "rehype-sanitize"
import rehypeStringify from "rehype-stringify"
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyBEeVWBYFHtjBYe9grDCxt4T0ftjQRPg8Y",
  authDomain: "autho.zango.ai",
  projectId: "bright-modem-417310",
  storageBucket: "bright-modem-417310.appspot.com",
  messagingSenderId: "267246851117",
  appId: "1:267246851117:web:42c470350dcb5cd4de94a4",
  measurementId: "G-DK0CP98TTQ"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)


export default class extends Controller {
  static targets = ["btnViewPolicy",  "rightPanel", "chatQueryInput", "query", "chatContainer", "btnCreatePolicy", "newPolicyForm", "createPolicyFeedback",
                    "debugContainer", "debugExtraContainer", "pdfPanel", "explorer", "selectGapRulesPanel", "testProgressLabel", "testProgressBar", "selectRule", "gapsTable",
                    "sourceViewer", "policiesContainer", "navbarItem", "alertLink", "alertDetail"]

  static values = {
    chatId: { type: Number, default: 0 },
    currentChatDocId: { type: String},
    currentChatPolicyId: { type: String},
    currentPolicySignedUrl: {type: String},
    currentPolicyFilename: {type: String},
    currentChatAlertId: { type: String},
  }

  static debounceTimeout = null
  static pollingTimeoutId = null
  static pollingStartTime = null
  static socket = null
  static epoch = null
  static reconnectTimeoutId = null
  static shouldReconnect = false
  static socketErrorCount = 0

  initialize() {

  }

  connect() {
    this.setupFrameLoadListener()
    checkAuthState(auth)
  }

  disconnect() {
    this.shouldReconnect = false
    try {
      this.socket.close()
    } catch {}
  }

  resetPing() {
    clearInterval(this.debounceTimeout)
    this.debounceTimeout = setInterval(() => {
        this.socket.send('ping')
    }, 10000)
  }

  connectWebsocket() {
    if (this.socket) {
        this.socket.close()
    }
    const wsScheme = window.location.protocol === "https:" ? "wss" : "ws"
    const wsHost = window.location.host
    // debug = true if DEBUG_RAG is set to true
    const debugRag = DEBUG_RAG == '1' ? true : false
    // because these static varaiables are defined as strings, we need to check for 'null' string
    const docidParam = (this.currentChatDocIdValue && this.currentChatDocIdValue != 'null') ? `&doc_id=${this.currentChatDocIdValue}` : ''
    const policyidParam = (this.currentChatPolicyIdValue && this.currentChatPolicyIdValue != 'null') ? `&policy_id=${this.currentChatPolicyIdValue}` : ''
    const alertidParam = (this.currentChatAlertIdValue && this.currentChatAlertIdValue != 'null') ? `&alert_id=${this.currentChatAlertIdValue}` : ''

    
    this.socket = new WebSocket(`${wsScheme}://${wsHost}/chat?debug=${debugRag}${docidParam}${policyidParam}${alertidParam}`)
    this.socket.onopen = () => {
        try {
            //hideElems('invisible', this.errorTarget)
            //hideElems('hidden', this.wsLoaderOverlayTarget)
          this.socketErrorCount = 0
          this.enableChat()
        } catch {}
        
       
        if(this.reconnectTimeoutId) {
            clearTimeout(this.reconnectTimeoutId)
            this.reconnectTimeoutId = null
        }

        this.resetPing()
    }

    this.socket.onmessage = (event) => {
        this.resetPing()
        this.handleResponse(event.data)
    }
    this.socket.onclose = (event) => {
        console.log('Disconnected from websocket', event)
        if (!this.shouldReconnect) {
            return
        }

        try {
            //showElems(this.errorTarget)
            //showElems(this.wsLoaderOverlayTarget)
        } catch {}
        
        this.reconnectWebsocket()
        
    }
    this.socket.onerror = (event) => {
        console.log('Error with websocket', event )
        if (!this.shouldReconnect) {
            return
        }

        if (this.socketErrorCount > 2) {
          // assume 403/401 auth error
          window.location = window.location.origin
        }

        this.socketErrorCount += 1

        try {
            //showElems(this.errorTarget)
            this.reconnectWebsocket()
        } catch {}
        
        
    }
  }

  reconnectWebsocket() {
    console.log('WebSocket attempting to reconnect...')
    if (this.reconnectTimeoutId) {
        clearTimeout(this.reconnectTimeoutId)
    }
    this.reconnectTimeoutId = setTimeout(() => {
        this.connectWebsocket()
    }, 5000)
  }



  createConversationContainer(chatId) {
    const conversationContainer = document.createElement('div')
    conversationContainer.id = `${chatId}-conversation`

    const queryContainer = document.createElement('div')
    queryContainer.id = `${chatId}-query`
    conversationContainer.appendChild(queryContainer)

    const answerContainer = document.createElement('div')
    answerContainer.id = `${chatId}-answer`
    conversationContainer.appendChild(answerContainer)

    this.chatContainerTarget.insertBefore(conversationContainer, this.chatQueryInputTarget)

    return [queryContainer, answerContainer]
  }

  createQueryHTML(query, gray = 500) {
    return `
        <div class="mt-12 rounded-md sm:flex sm:items-start sm:justify-between">
            <h4 class="sr-only">You</h4>
            <div class="sm:flex sm:items-start">
                <div class="sm:mt-2">
                    <p class="text-sm text-gray-${gray} prose prose-sm"><strong class="font-semibold">${query}</strong></p>
                </div>
            </div>
        </div>
    `
  }

  createAnswerHTML(chatId) {
    return `
        <div class="mt-3 rounded-md sm:flex sm:items-start sm:justify-between">
        <h4 class="sr-only">Zango AI</h4>
        <div class="sm:flex sm:items-start">

            <div class="sm:mt-2">
                <span class="text-sm text-gray-700 prose prose-sm prose-a:hidden prose-img:hidden" id="${chatId}-answer-text" data-markdown=""></span>
                <span id="${chatId}-answer-cursor">
                    <span class="inline-flex h-3 w-3">
                        <span class="relative inline-flex rounded-sm h-5 w-2 bg-violet-400"></span>
                        <span class="animate-ping  inline-flex h-5 w-2 rounded-sm bg-violet-500 opacity-75"></span>
                    </span>
                </span>
                <span id="${chatId}-answer-sources"></span>
                <span id="${chatId}-answer-related"></span>
                <span id="${chatId}-answer-urls" class="invisible text-xs">
                    <details class="mt-5">
                        <summary>Data Sources</summary>
                        <span id="${chatId}-answer-urls-list"></span>
                    </details>
                </span>
                <span id="${chatId}-answer-tags" class="invisible text-xs">
                    <details class="mt-5">
                        <summary>Tags</summary>
                        <span id="${chatId}-answer-tags-list"></span>
                    </details>
                </span>
            </div>
        </div>
        </div>
    `
  }

  sendMessage(event) {
    event.preventDefault()

    if (this.socket.readyState !== WebSocket.OPEN) {
        console.error('WebSocket not open:', this.socket.readyState)
        return
    }

    let message = null
    switch (event.params.requestType) {
        case 'query':
            // because enter key trigger doesn't seem to work for input fields even when using hotkeys lib!
            if (event.target == this.queryTarget && event.key != 'Enter') {
                return
            }
            message = this.queryTarget.value.trim()
            this.queryTarget.value = ''
            break
        default:
            break
    }

    disableElems(this.queryTarget)


    if (message) {
        this.chatIdValue += 1
        const [queryContainer, answerContainer] = this.createConversationContainer(this.chatIdValue)
        const queryHtml = this.createQueryHTML(message, 400)
        queryContainer.innerHTML = queryHtml
        const answerHTML = this.createAnswerHTML(this.chatIdValue)
        answerContainer.innerHTML = answerHTML
        this.socket.send(message)
    }

    enableElems(this.queryTarget)
  }

  handleResponse(response) {
    const answerTextContainer = document.getElementById(`${this.chatIdValue}-answer-text`)
    const answerCursorContainer = document.getElementById(`${this.chatIdValue}-answer-cursor`)
    const answerRelatedContainer = document.getElementById(`${this.chatIdValue}-answer-related`)
    const answerUrlsContainer = document.getElementById(`${this.chatIdValue}-answer-urls`)
    const answerUrlsListContainer = document.getElementById(`${this.chatIdValue}-answer-urls-list`)
    const answerTagsContainer = document.getElementById(`${this.chatIdValue}-answer-tags`)
    const answerTagsListContainer = document.getElementById(`${this.chatIdValue}-answer-tags-list`)
    const answerSourcesContainer = document.getElementById(`${this.chatIdValue}-answer-sources`)
    const reply = JSON.parse(response)
    switch (reply.type) {
        case 'start':
          this.debugContainerTarget.innerHTML = ''
          this.debugExtraContainerTarget.innerHTML = ''
        case 'stream':
            if (reply.sender == 'you') {
                const queryContainer = document.getElementById(`${this.chatIdValue}-query`)
                const queryHtml = this.createQueryHTML(reply.message, 700)
                queryContainer.innerHTML = queryHtml
                this.epoch = new Date()
            } else {
                const markdown = `${answerTextContainer.getAttribute('data-markdown')}${reply.message}`
                answerTextContainer.setAttribute('data-markdown', markdown)
                answerTextContainer.innerHTML = this.markdownToHTML(markdown)
                this.queryTarget.scrollIntoView({ behavior: 'instant', block: 'end', inline: 'nearest' })
            }
            break
        case 'related':
            const relatedDiv = document.createElement('div')
            relatedDiv.innerHTML = `<div class="mt-3 rounded-md sm:flex sm:items-start sm:justify-between">
                <div class="sm:flex sm:items-start">
                <div class="sm:mt-0">
                    <p class="text-center text-sm text-gray-500">
                    <span class="inline-flex items-center text-left gap-x-0.5 rounded-md px-2 py-1 text-xs font-medium bg-violet-50 text-gray-600 ring-1 ring-inset ring-violet-500/10 hover:bg-violet-500/20">
                        <a href="" data-copilot-request-type-param="query" data-action="copilot#enterPresetQuery">${reply.message}</a>
                        <span class="group relative -mr-1 h-3.5 w-3.5 rounded-sm ">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-3.5 w-3.5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 4.5l15 15m0 0V8.25m0 11.25H8.25"></path>
                        </svg>
                        <span class="absolute -inset-1"></span>
                        </span>
                    </span>
                    </p>
                </div>
                </div>
            </div>`
            answerRelatedContainer.appendChild(relatedDiv)

            //for debug
            if (DEBUG_RAG != '1') {
                break
            }
            const debugRelated= document.createElement('li')
            debugRelated.classList.add('relative', 'flex', 'gap-x-4')
            debugRelated.innerHTML = `
              <div class="absolute left-0 top-0 flex w-6 justify-center -bottom-6">
                <div class="w-px bg-gray-200"></div>
              </div>
              <svg class="relative mt-3 h-6 w-6 flex-none rounded-full border border-gray-50 bg-gray-50" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 7.5 16.5-4.125M12 6.75c-2.708 0-5.363.224-7.948.655C2.999 7.58 2.25 8.507 2.25 9.574v9.176A2.25 2.25 0 0 0 4.5 21h15a2.25 2.25 0 0 0 2.25-2.25V9.574c0-1.067-.75-1.994-1.802-2.169A48.329 48.329 0 0 0 12 6.75Zm-1.683 6.443-.005.005-.006-.005.006-.005.005.005Zm-.005 2.127-.005-.006.005-.005.005.005-.005.005Zm-2.116-.006-.005.006-.006-.006.005-.005.006.005Zm-.005-2.116-.006-.005.006-.005.005.005-.005.005ZM9.255 10.5v.008h-.008V10.5h.008Zm3.249 1.88-.007.004-.003-.007.006-.003.004.006Zm-1.38 5.126-.003-.006.006-.004.004.007-.006.003Zm.007-6.501-.003.006-.007-.003.004-.007.006.004Zm1.37 5.129-.007-.004.004-.006.006.003-.004.007Zm.504-1.877h-.008v-.007h.008v.007ZM9.255 18v.008h-.008V18h.008Zm-3.246-1.87-.007.004L6 16.127l.006-.003.004.006Zm1.366-5.119-.004-.006.006-.004.004.007-.006.003ZM7.38 17.5l-.003.006-.007-.003.004-.007.006.004Zm-1.376-5.116L6 12.38l.003-.007.007.004-.004.007Zm-.5 1.873h-.008v-.007h.008v.007ZM17.25 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Zm0 4.5a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
              </svg>

              <div class="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 bg-white">
                <div class="flex justify-between gap-x-4">
                <div class="py-0.5 text-xs leading-5 text-gray-500"><span class="font-medium text-gray-900">Related questions</span> ${reply.type}</div>
          
                </div>
                <div class="prose prose-sm max-w-full bg-white mb-10">${reply.message}</div>
              </div>
            `
            this.debugExtraContainerTarget.appendChild(debugRelated)
            break
        case 'url':
            const urlDiv = document.createElement('div')
            urlDiv.innerHTML = `<div class="sm:flex sm:items-start">
                <div class="sm:ml-2 sm:mt-0">
                    <p class="text-center text-sm text-blue-500">
                        <span class="inline-flex items-center text-left gap-x-0.5 rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-blue-600 ring-1 ring-inset ring-blue-500/10 hover:bg-blue-500/20">
                            <a href="${reply.message}" target="_blank" referrerpolicy="no-referrer">${reply.message}</a>
                            <span class="group relative -mr-1 h-3.5 w-3.5 rounded-sm">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-3.5 w-3.5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                            </svg>
                            <span class="absolute -inset-1"></span>
                            </span>
                        </span>
                    </p>
                </div>
                </div>`
            answerUrlsListContainer.appendChild(urlDiv)
            showElems(answerUrlsContainer)
            this.queryTarget.scrollIntoView({ behavior: 'instant', block: 'end', inline: 'nearest' })
          break
        case 'tag':
            const tagDiv = document.createElement('div')
            tagDiv.innerHTML = `<div class="sm:flex sm:items-start">
                <div class="sm:ml-2 sm:mt-1">
                    <p class="text-center text-sm text-blue-500">
                        <span class="inline-flex items-center text-left gap-x-0.5 rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-blue-600 ring-1 ring-inset ring-blue-500/10">
                            ${reply.message}
                        </span>
                    </p>
                </div>
                </div>`
            answerTagsListContainer.appendChild(tagDiv)
            showElems(answerTagsContainer)

            //for debug
            if (DEBUG_RAG != '1') {
              break
            }
            const debugTags= document.createElement('li')
            debugTags.classList.add('relative', 'flex', 'gap-x-4')
            debugTags.innerHTML = `
              <div class="absolute left-0 top-0 flex w-6 justify-center -bottom-6">
                <div class="w-px bg-gray-200"></div>
              </div>
              <svg class="relative mt-3 h-6 w-6 flex-none rounded-full border border-gray-50 bg-gray-50" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 7.5 16.5-4.125M12 6.75c-2.708 0-5.363.224-7.948.655C2.999 7.58 2.25 8.507 2.25 9.574v9.176A2.25 2.25 0 0 0 4.5 21h15a2.25 2.25 0 0 0 2.25-2.25V9.574c0-1.067-.75-1.994-1.802-2.169A48.329 48.329 0 0 0 12 6.75Zm-1.683 6.443-.005.005-.006-.005.006-.005.005.005Zm-.005 2.127-.005-.006.005-.005.005.005-.005.005Zm-2.116-.006-.005.006-.006-.006.005-.005.006.005Zm-.005-2.116-.006-.005.006-.005.005.005-.005.005ZM9.255 10.5v.008h-.008V10.5h.008Zm3.249 1.88-.007.004-.003-.007.006-.003.004.006Zm-1.38 5.126-.003-.006.006-.004.004.007-.006.003Zm.007-6.501-.003.006-.007-.003.004-.007.006.004Zm1.37 5.129-.007-.004.004-.006.006.003-.004.007Zm.504-1.877h-.008v-.007h.008v.007ZM9.255 18v.008h-.008V18h.008Zm-3.246-1.87-.007.004L6 16.127l.006-.003.004.006Zm1.366-5.119-.004-.006.006-.004.004.007-.006.003ZM7.38 17.5l-.003.006-.007-.003.004-.007.006.004Zm-1.376-5.116L6 12.38l.003-.007.007.004-.004.007Zm-.5 1.873h-.008v-.007h.008v.007ZM17.25 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Zm0 4.5a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
              </svg>

              <div class="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 bg-white">
                <div class="flex justify-between gap-x-4">
                <div class="py-0.5 text-xs leading-5 text-gray-500"><span class="font-medium text-gray-900">Tags</span> ${reply.type}</div>
          
                </div>
                <div class="prose prose-sm max-w-full bg-white mb-10">${reply.message}</div>
              </div>
            `
            this.debugExtraContainerTarget.appendChild(debugTags)
            this.queryTarget.scrollIntoView({ behavior: 'instant', block: 'end', inline: 'nearest' })
          break
        case 'page':
            //for debug
            if (DEBUG_RAG != '1') {
              break
            }
            const debugPage = document.createElement('li')
            debugPage.classList.add('relative', 'flex', 'gap-x-4')
            debugPage.innerHTML = `
              <div class="absolute left-0 top-0 flex w-6 justify-center -bottom-6">
                  <div class="w-px bg-gray-200"></div>
              </div>
              <svg class="relative mt-3 h-6 w-6 flex-none rounded-full border border-gray-50 bg-gray-50" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 7.5 16.5-4.125M12 6.75c-2.708 0-5.363.224-7.948.655C2.999 7.58 2.25 8.507 2.25 9.574v9.176A2.25 2.25 0 0 0 4.5 21h15a2.25 2.25 0 0 0 2.25-2.25V9.574c0-1.067-.75-1.994-1.802-2.169A48.329 48.329 0 0 0 12 6.75Zm-1.683 6.443-.005.005-.006-.005.006-.005.005.005Zm-.005 2.127-.005-.006.005-.005.005.005-.005.005Zm-2.116-.006-.005.006-.006-.006.005-.005.006.005Zm-.005-2.116-.006-.005.006-.005.005.005-.005.005ZM9.255 10.5v.008h-.008V10.5h.008Zm3.249 1.88-.007.004-.003-.007.006-.003.004.006Zm-1.38 5.126-.003-.006.006-.004.004.007-.006.003Zm.007-6.501-.003.006-.007-.003.004-.007.006.004Zm1.37 5.129-.007-.004.004-.006.006.003-.004.007Zm.504-1.877h-.008v-.007h.008v.007ZM9.255 18v.008h-.008V18h.008Zm-3.246-1.87-.007.004L6 16.127l.006-.003.004.006Zm1.366-5.119-.004-.006.006-.004.004.007-.006.003ZM7.38 17.5l-.003.006-.007-.003.004-.007.006.004Zm-1.376-5.116L6 12.38l.003-.007.007.004-.004.007Zm-.5 1.873h-.008v-.007h.008v.007ZM17.25 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Zm0 4.5a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
              </svg>

              <div class="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 bg-white">
                <div class="flex justify-between gap-x-4">
                <div class="py-0.5 text-xs leading-5 text-gray-500"><span class="font-medium text-gray-900">Page</span> ${reply.type}</div>
          
                </div>
                <div class="prose prose-sm max-w-full bg-white mb-10">${reply.message}</div>
              </div>
            `
            this.debugExtraContainerTarget.appendChild(debugPage) 
            break
        case 'sources':
          const sourceDocs = []
          let sourceCount = 0
          reply?.message?.forEach((doc) => {
            let content
            if (doc.source_id == 'image') {
                content = `<img src="${doc.url}" class="w-32 h-32 object-cover" alt="source image">`
            } else {
              content = doc.content?.substring(0, 32)?.replace(/[^a-zA-Z0-9]/g, ' ')
            }
            const sourceDoc = `<a href="document_viewer/${doc.id}" data-turbo-frame="sourceViewer" class="bg-white rounded-lg p-4 shadow-md flex-shrink-0 block hover:bg-violet-500/20 w-48 truncate text-ellipsis overflow-hidden ...">
             <div class="flex items-center">
                <div class="text-xs max-w-32 w-32 truncate text-ellipsis overflow-hidden ...">${content}</div>
             </div>
             <div class="text-xs text-gray-600 mt-2 max-w-32 w-32 truncate text-ellipsis overflow-hidden ...">${doc.tags ? doc.tags: ''}</div>
             </a>`
            sourceDocs.push(sourceDoc)
            sourceCount += 1
          })
          const sourcesDiv = document.createElement('div')
          sourcesDiv.classList.add('bg-gray-100', 'p-6', 'overflow-x-auto', 'max-w-prose')
          sourcesDiv.innerHTML = `<div class="font-semibold mb-4">Sources (${sourceCount})</div>
            <div class="flex overflow-x-auto space-x-2">${sourceDocs.join('')}</div>`
          answerSourcesContainer.appendChild(sourcesDiv)
        case 'end':
            // If there are links in the answer, make them clickable
            // performant as we only do DOM manipulation if there are links
            // safer than linkifyHTML as linkifyElement will escape html and only allow a tags
            const links = linkify.find(answerTextContainer.innerText)
            if (links.length > 0) {
                linkifyElement(answerTextContainer)
            }

            // construct URL for PDF viewer
            const signedUrl = encodeToBase64(this.currentPolicySignedUrlValue)
            const pdfPageLinks = answerTextContainer.querySelectorAll('a')
            pdfPageLinks.forEach((link) => {
              // ger link's parent elements textcontent
              //const linkParentText = link.parentElement.textContent
                
              //const citationText = encodeToBase64(linkParentText)

              const originalHref = link.getAttribute('href')
              if (originalHref.startsWith('null')) {
                /**
                 * Displayig the source citation with PDFjs is a resource intensive task requiring a lot of memory and the highlighting doesn't work well.
                 * So we are disabling the links to the source citation links for now
                 */
                disableElems(link)
                hideElems('hidden', link)
                /* -- uncomment this block to enable source citation links
                const pageId = originalHref.split('/')[1]
                if (isNaN(parseInt(pageId))) {
                  link.removeAttribute('href')
                  disableElems(link)
                  hideElems('hidden', link)
                } else {
                  const newHref = `/pdf?name=${this.currentPolicyFilenameValue}&url=${signedUrl}&page=${pageId}&citation=${citationText}`
                  link.href = newHref
                  link.setAttribute('data-turbo-frame', 'pdfpanel')
                } -- uncomment this block to enable source citation links */
              } else if (!originalHref.startsWith('document_viewer')) {
                const regex = /^(?:document\/)?(.+)$/
                const match = originalHref.match(regex)
                if (match) {
                const newHref = `document_viewer/${match[1]}`
                link.setAttribute('href', newHref)
                link.setAttribute('data-turbo-frame', 'sourceViewer')
                } else {
                  // we want tnothing to happen when clicked
                  link.setAttribute('href', 'javascript:void(0)')
                }
              }
            })

            answerTextContainer.classList.remove('prose-a:hidden', 'prose-img:hidden')
            answerCursorContainer.innerHTML = ''
            this.queryTarget.placeholder = 'Ask a follow up question. Note: for new questions please click on start new chat thread'
            this.queryTarget.scrollIntoView({ behavior: 'instant', block: 'end', inline: 'nearest' })
            break
        case 'error':
            answerTextContainer.innerText = `${answerTextContainer.innerText} Sorry, there was an error. Please try again.`
            answerCursorContainer.innerHTML = ''
            this.queryTarget.scrollIntoView({ behavior: 'instant', block: 'end', inline: 'nearest' })
            break
        case 'debug':
          if (DEBUG_RAG != '1') {
            break
          }
          let msg = reply?.message
          if (reply.sub_type == 'ctx_docs' || reply.sub_type == 'ctx_docs_alt') {
             const docs = []
             reply?.message?.forEach((doc) => { 
                const debug_log = `
                  <div class="inline-flex items-center rounded-md bg-purple-100 px-2 py-1 text-xs font-medium text-purple-700">id: ${doc.id}</div>
                  <div class="inline-flex items-center rounded-md bg-purple-100 px-2 py-1 text-xs font-medium text-purple-700">document id: ${doc.document_id}</div>
                  <div class="inline-flex items-center rounded-md bg-purple-100 px-2 py-1 text-xs font-medium text-purple-700">parent id: ${doc.parent_id}</div>
                  <div class="inline-flex items-center rounded-md bg-purple-100 px-2 py-1 text-xs font-medium text-purple-700">source id: ${doc.source_id}</div>
                  <div class="inline-flex items-center rounded-md bg-purple-100 px-2 py-1 text-xs font-medium text-purple-700">page num: ${doc.source}</div>
                  <div class="inline-flex items-center rounded-md bg-purple-100 px-2 py-1 text-xs font-medium text-purple-700">cluster id: ${doc.cluster_id}</div>
                  <div class="inline-flex items-center rounded-md bg-purple-100 px-2 py-1 text-xs font-medium text-purple-700">score: ${doc.score}</div>
                  <div>text: ${doc.content}</div>
                `
                docs.push(debug_log)
              })
              msg = docs.join('')
          }
           const debugMsgElem= document.createElement('li')
           debugMsgElem.classList.add('relative', 'flex', 'gap-x-4')
           debugMsgElem.innerHTML = `
              <div class="absolute left-0 top-0 flex w-6 justify-center -bottom-6">
                <div class="w-px bg-gray-200"></div>
              </div>
              <svg class="relative mt-3 h-6 w-6 flex-none rounded-full border border-gray-50 bg-gray-50" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 7.5 16.5-4.125M12 6.75c-2.708 0-5.363.224-7.948.655C2.999 7.58 2.25 8.507 2.25 9.574v9.176A2.25 2.25 0 0 0 4.5 21h15a2.25 2.25 0 0 0 2.25-2.25V9.574c0-1.067-.75-1.994-1.802-2.169A48.329 48.329 0 0 0 12 6.75Zm-1.683 6.443-.005.005-.006-.005.006-.005.005.005Zm-.005 2.127-.005-.006.005-.005.005.005-.005.005Zm-2.116-.006-.005.006-.006-.006.005-.005.006.005Zm-.005-2.116-.006-.005.006-.005.005.005-.005.005ZM9.255 10.5v.008h-.008V10.5h.008Zm3.249 1.88-.007.004-.003-.007.006-.003.004.006Zm-1.38 5.126-.003-.006.006-.004.004.007-.006.003Zm.007-6.501-.003.006-.007-.003.004-.007.006.004Zm1.37 5.129-.007-.004.004-.006.006.003-.004.007Zm.504-1.877h-.008v-.007h.008v.007ZM9.255 18v.008h-.008V18h.008Zm-3.246-1.87-.007.004L6 16.127l.006-.003.004.006Zm1.366-5.119-.004-.006.006-.004.004.007-.006.003ZM7.38 17.5l-.003.006-.007-.003.004-.007.006.004Zm-1.376-5.116L6 12.38l.003-.007.007.004-.004.007Zm-.5 1.873h-.008v-.007h.008v.007ZM17.25 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Zm0 4.5a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
              </svg>

              <div class="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 bg-white">
                <div class="flex justify-between gap-x-4">
                  <div class="py-0.5 text-xs leading-5 text-gray-500"><span class="font-medium text-gray-900">${reply.sub_type ? reply.sub_type : reply.sender}</span> ${reply.type}</div>
          
                </div>
                <div class="prose prose-sm max-w-full bg-white mb-10">${msg}</div>
              </div>`
            this.debugContainerTarget.appendChild(debugMsgElem)
            break
        default:
            break
    }
  }


  setupFrameLoadListener(){
    document.addEventListener("turbo:frame-load", event => {
        const frame = event.target
        const frameElem = frame.querySelector('[data-frame-type]')
        const frameType = frameElem?.getAttribute('data-frame-type')
        switch (frameType) {
            case 'policyDetail':
            case 'explorer':
                this.formatMarkdownSections(frameElem)
                break
            case 'sourceViewer':
              this.formatSourceDoc(frameElem)
              break
            case 'chat':
                this.shouldReconnect = true
                this.socketErrorCount = 0
                this.connectWebsocket()
                break
            case 'policies-container':
              try {
                const btnViewPolicy = frameElem.querySelector('[data-copilot-target="btnViewPolicy"]')
                btnViewPolicy.click()
              } catch (error) {console.error('Error clicking view policy button:', error)}
            case 'alertContainer':
                const alertLink = frameElem.querySelector('[data-copilot-target="alertLink"]')
                alertLink?.click()  
            default:
                break
        }

    })

    document.addEventListener("turbo:before-fetch-response", event => {
        const response = event.detail.fetchResponse.response
        const status = response.status
        if (status == 401) {
          event.preventDefault()
          window.location = `${window.location.origin}/sign_in`
        }
    } ) 
}

  markdownToHTML(markdown) {
    return unified()
        .use(remarkParse)
        .use(remarkGfm)
        .use(remarkRehype)
        .use(rehypeSanitize)
        .use(rehypeStringify)
        .processSync(markdown)
        .toString()
  }

  formatMarkdownSections(contentDiv) {
    try {
        const markdownElems = contentDiv.querySelectorAll('[data-markdown]')
        markdownElems.forEach((markdownElem) => {
            const markdown = markdownElem.getAttribute('data-markdown')
            markdownElem.innerHTML = this.markdownToHTML(markdown)

            const refs = markdownElem.getAttribute('data-refs')
            const topic = markdownElem.getAttribute('data-topic')

            const links = markdownElem.querySelectorAll('a')
            if (!refs) {
              links.forEach((link) => {
                link.setAttribute('data-turbo-frame', 'pdfpanel')
              })
            } else {
              const refContainer = document.createElement('div')
             
              var hrefs = ''
              refs.split(',').forEach((ref, idx) => {
                hrefs += `<span class="hover:bg-violet-500/20 inline-flex items-center rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-600 ml-2"><a  href="#" data-target-id="${ref}" data-action="copilot#showRef">${idx+1}</a></span>`
              })
              refContainer.innerHTML = `
                <div ><span class="font-semibold">Topic: </span>${topic}<span></div>
                <div><span class="font-semibold">Reference sections: ${hrefs}
              </div>
              `
              markdownElem.appendChild(refContainer)
            }

        })
      } catch (error) {}
  }

  expandSection(event) {
    const icons = event.currentTarget.querySelectorAll("svg")
    icons.forEach((icon) => {
      icon.classList.toggle("hidden")
    })

    const contentId = event.currentTarget.getAttribute("aria-controls")
    const content = document.getElementById(contentId)
    const markdown = content.getAttribute('data-markdown')
    if (markdown) {
      content.innerHTML = this.markdownToHTML(markdown)
    }
    content.classList.toggle("hidden")
  }

  viewPolicy(event) {
    const policyId = event.currentTarget.getAttribute("data-policy-id")
    const chatType = event.currentTarget.getAttribute("data-chat-type")
  
    const directClick = event.currentTarget.getAttribute('data-button-name') != 'btnViewChatPolicy' ? true : false
    if (directClick) {
        this.btnViewPolicyTargets.forEach((btn) => {
            btn.classList.remove("bg-violet-600", "hover:bg-violet-500", "text-white")
          })
        event.currentTarget.classList.add("bg-violet-600", "hover:bg-violet-500", "text-white")
    }

    if (chatType == 'public') {
      this.rightPanelTarget.setAttribute("src", `/share/policy_detail/${policyId}`)
    } else {
      this.rightPanelTarget.setAttribute("src", `/policy_detail/${policyId}`)
    }
    
  }

  copyShareLink(policyId, btn) {
    const rootDomain = window.location.origin
    navigator.clipboard.writeText(`${rootDomain}/share/${policyId}`)
    btn.setAttribute("data-policy-is-public", "1")
    btn.innerText = 'Link copied!' 
    setTimeout(() => {
      btn.classList.remove('animate-pulse')
      btn.innerText = 'Create public link'
      enableElems(btn)
    }, 
    5000)
  }

  async sharePolicy(event) {
    event.preventDefault()
    disableElems(event.currentTarget)
    const btn = event.currentTarget
    btn.classList.add('animate-pulse')
    const policyId = btn.getAttribute("data-policy-id")
    const isPublic = btn.getAttribute("data-policy-is-public")
    if (isPublic != "1") {
      fetch (
        `/policies/${policyId}/share`,
        {
          method: 'PUT',
          mode: 'cors',
          credentials: 'same-origin',
          cache: 'no-cache',
          headers: {},
        }).then(response => {
            if (response.ok) {
              return response
            }
            throw Error(response.statusText)
          }).then(response => {
              this.copyShareLink(policyId, btn)
            }).catch(error => {
                console.log('Error: ', error)
                btn.innerText = 'Error sharing policy'
                setTimeout(() => {
                  btn.classList.remove('animate-pulse')
                  btn.innerText = 'Create public link'
                  enableElems(btn)
                }, 
                5000)
            })
    } else {
      this.copyShareLink(policyId, btn)
    }
  }

  showGapAnalysis(event) {
    const policyId = event.currentTarget.getAttribute("data-policy-id")
    const policyName = event.currentTarget.getAttribute("data-policy-name")
    const docId = event.currentTarget.getAttribute("data-policy-doc-id")
    this.rightPanelTarget.setAttribute("src", `/gap_analysis_container?policy_id=${policyId}&policy_name=${policyName}&doc_id=${docId}`)
  }

  getPolicyRules(event) {
    const policyId = event.currentTarget.value
    this.selectGapRulesPanelTarget.setAttribute("src", `/gap_analysis_policy_rules?policy_id=${policyId}`)
  }

  startChat(event) {
    const policyId = event.currentTarget.getAttribute("data-policy-id")
    const policyDocId = event.currentTarget.getAttribute("data-policy-doc-id") 
    const alertId = event.currentTarget.getAttribute("data-alert-id")
    this.currentChatPolicyIdValue = policyId
    this.currentChatDocIdValue = policyDocId
    this.currentPolicySignedUrlValue = event.currentTarget.getAttribute("data-policy-signed-url") 
    this.currentPolicyFilenameValue = event.currentTarget.getAttribute("data-policy-filename") 
    this.currentChatAlertIdValue = alertId
    if (policyId) {
    this.rightPanelTarget.setAttribute("src", `/chat_container?policy_id=${policyId}`)
    } else if (alertId) {
      this.alertDetailTarget.setAttribute("src", `/chat_container?alert_id=${alertId}`)
    } else {
      this.rightPanelTarget.setAttribute("src", `/chat_container`)
    }
  }

  startPublicChat(event) {
    const policyId = event.currentTarget.getAttribute("data-policy-id")
    const policyDocId = event.currentTarget.getAttribute("data-policy-doc-id")
    this.currentChatPolicyIdValue = policyId
    this.currentChatDocIdValue = policyDocId
    this.currentPolicySignedUrlValue = event.currentTarget.getAttribute("data-policy-signed-url")
    this.currentPolicyFilenameValue = event.currentTarget.getAttribute("data-policy-filename")
    this.rightPanelTarget.setAttribute("src", `/share/chat_container/${policyId}`)
  }

  enableChat() {
    this.queryTarget.placeholder = 'Ask Zango AI'
    enableElems(this.queryTarget)
    this.chatQueryInputTarget.classList.remove('animate-pulse')
  }

  resetChat(event) {
    this.chatQueryInputTarget.classList.add('animate-pulse')
    this.queryTarget.placeholder = 'Opening new chat session ... '
    disableElems(this.queryTarget)
    this.connectWebsocket()
  }

  newPolicy(event) {
    this.rightPanelTarget.setAttribute("src", "/new_policy")
  }

  createPolicy(event) {
    event.preventDefault()
    if (!validateForm(this.newPolicyFormTarget)) {
        return
      }

    this.btnCreatePolicyTarget.classList.add('animate-pulse')
    disableElems(this.btnCreatePolicyTarget)

    const form = new FormData(this.newPolicyFormTarget)

    fetch (
        `/policies`,
        {
          method: 'POST',
          mode: 'cors',
          credentials: 'same-origin',
          cache: 'no-cache',
          headers: {},
        body: form
        }).then(response => {
            if (response.ok) {
              return response
            }
            throw Error(response.statusText)
          }).then(response => {
              return response.json()
            }).then(response => {
                this.btnCreatePolicyTarget.classList.remove('animate-pulse')
                const successMsg = `<div class="rounded-md bg-green-50 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div class="ml-3">
                    <p class="text-sm font-medium text-green-800">Successfully uploaded.<span class="animate-pulse"> Zango AI is processing the document now ...</span></p>
                  </div>
                  <div class="ml-auto pl-3">
                    <div class="-mx-1.5 -my-1.5">
                      <button type="button" class="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50">
                        <span class="sr-only">Dismiss</span>
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              `
                this.createPolicyFeedbackTarget.innerHTML = successMsg
                this.pollingStartTime = Date.now()
                clearTimeout(this.pollingTimeoutId)
                this.pollingTimeoutId = setTimeout(() => {this.pollPolicyStatus(response.id, this.createPolicyFeedbackTarget)}, 30000)
                
            }).catch(error => {
                console.log('Error: ', error)
                enableElems(this.btnCreatePolicyTarget)
                this.btnCreatePolicyTarget.classList.remove('animate-pulse')
                const errorMsg = `<div class="rounded-md bg-red-50 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div class="ml-3">
                    <h3 class="text-sm font-medium text-red-800">Error creating policy, please retry.</h3>
                  </div>
                </div>
              </div>`
                this.createPolicyFeedbackTarget.innerHTML = errorMsg
            })

  }


  pollPolicyStatus(policyId, feedbackContainer)  {
    try {
      fetch (
        `/policies/${policyId}`,
        {
          method: 'GET',
          mode: 'cors',
          credentials: 'same-origin',
          cache: 'no-cache',
          headers: {},
        }).then(response => {
          if (response.ok) {
            return response
          }
          throw Error(response.statusText)
        }).then(response => {
            return response.json()
          })
          .then(response => {
            if (response.status == 'processed') {
                const successMsg = `<div class="rounded-md bg-green-50 p-4">
                    <div class="flex">
                    <div class="flex-shrink-0">
                        <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <div class="ml-3">
                        <p class="text-sm font-medium text-green-800">Your policy has been processed successfully, reloading policies list<span class="animate-pulse">...</span></p>
                    </div>
                    <div class="ml-auto pl-3">
                        <div class="-mx-1.5 -my-1.5">
                        <button type="button" class="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50">
                            <span class="sr-only">Dismiss</span>
                            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                            </svg>
                        </button>
                        </div>
                    </div>
                    </div>
                    </div>
                `
              feedbackContainer.innerHTML = successMsg
              setTimeout(() => {
                const policiesContainer = document.getElementById('policies-container')
                policiesContainer.reload()
              }, 5000)
            } else if (response.status == 'failed') {
                const errorMsg = `<div class="rounded-md bg-red-50 p-4">
                    <div class="flex">
                    <div class="flex-shrink-0">
                        <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <div class="ml-3">
                        <h3 class="text-sm font-medium text-red-800">Error processing policy, please retry.</h3>
                    </div>
                    </div>
                </div>`
                feedbackContainer.innerHTML = errorMsg
            } else {
                if (Date.now() - this.pollingStartTime < 60000 * 5) {
                  clearTimeout(this.pollingTimeoutId)
                  this.pollingTimeoutId = setTimeout(() => {this.pollPolicyStatus(policyId, feedbackContainer)}, 30000)
                } else {
                    const pendingMsg = `<div class="rounded-md bg-green-50 p-4">
                        <div class="flex">
                        <div class="flex-shrink-0">
                            <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                            </svg>
                        </div>
                        <div class="ml-3">
                            <p class="text-sm font-medium text-green-800">Your policy has been uploaded successfully. We need more time to analyse the documents. Please refresh the page in a few minutes.<span class="animate-pulse">...</span></p>
                        </div>
                        <div class="ml-auto pl-3">
                            <div class="-mx-1.5 -my-1.5">
                            <button type="button" class="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50">
                                <span class="sr-only">Dismiss</span>
                                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                </svg>
                            </button>
                            </div>
                        </div>
                        </div>
                        </div>
                    `
                    feedbackContainer.innerHTML = pendingMsg     
                    setTimeout(() => {
                        const policiesContainer = document.getElementById('policies-container')
                        policiesContainer.reload()
                     }, 5000)
                }
            }
          }).catch(error => {
            console.log('Error: ', error)
          })    
    } catch (error) {
      console.error('Error fetching policy status:', error)
    }
  }


  enterPresetQuery(event) {
    event.preventDefault()
    this.queryTarget.value = event.target.innerText
    this.sendMessage(event)
    this.queryTarget.scrollIntoView({ behavior: 'instant', block: 'nearest' })
  }

  closePdfPanel(event) {
    hideElems('invisible', this.pdfPanelTarget)
  }

  closeExplorer(event) {
    hideElems('invisible', this.explorerTarget)
  }

  closesourceViewer(event) {
    hideElems('invisible', this.sourceViewerTarget)
  }

  showRef(event) {
    event.preventDefault()
    const refId = event.currentTarget.getAttribute('data-target-id')
    // find by id
    const refElem = document.getElementById(refId)
    refElem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    refElem.classList.add('bg-violet-500/20')
    setTimeout(() => {
      refElem.classList.remove('bg-violet-500/20')
    }, 5000)
    
  }

  formatSourceDoc(contentDiv) {
    try {
        const markdownElems = contentDiv.querySelectorAll('[data-markdown]')
        markdownElems.forEach((markdownElem) => {
            const markdown = markdownElem.getAttribute('data-markdown')
            markdownElem.innerHTML = this.markdownToHTML(markdown)
        })
      } catch (error) {}
  }


  handleScoreSSE(event) {
    event.preventDefault()
    const docId = event.currentTarget.getAttribute("data-policy-doc-id")
    const testBtn = event.currentTarget
    testBtn.classList.add('animate-pulse')
    disableElems(testBtn)
    showElems(this.testProgressLabelTarget, this.testProgressBarTarget)
    let totalCount = 1
    let processedCount = 0
    let progressPercent = 0
    this.testProgressLabelTarget.innerHTML = `Analysis progress ${progressPercent.toFixed(0)}%`
    this.testProgressBarTarget.style.width = `${progressPercent.toFixed(0)}%`

    
    const url = new URL('/analysis', window.location.origin)
    url.searchParams.append('doc_id', docId)
    this.selectRuleTargets.forEach(rule => {
      // if input is checked select the id of input element
      if (rule.checked) {
        url.searchParams.append('rule_id', rule.id)
        totalCount += 1
      }
    })

    const eventSource = new EventSource(url)

    eventSource.onopen = (event) => {
        //console.log('Connection opened:', event)
    }

    eventSource.onmessage = (event) => {
        //console.log('Message received:', event.data)
    }

    eventSource.addEventListener('gapAnalysis', (event) => {
        const data = JSON.parse(event.data)
        const tableRow = document.createElement('tr')
        tableRow.classList.add('divide-x', 'divide-gray-200') 
        const analysis = data?.analysis?.analysis
        const analysisHtml = this.markdownToHTML(analysis)
        const entities = data?.entities ?? []
        const entitiesHtml = entities.map((entity) => {
          return `<li class="list-disc">${entity}</li>`
        }).join('')
        const conditions = data?.conditions ?? []
        const conditionsHtml = conditions.map((condition) => {
          return `<li class="list-disc">${condition}</li>`
        }).join('')
        const consequences = data?.consequences ?? []
        const consequencesHtml = consequences.map((consequence) => {
          return `<li class="list-disc">${consequence}</li>`
        }).join('')

        const ratings = data?.analysis?.ratings ?? []
        const obligations = data?.obligations ?? []
        const obligationsHtml = obligations.map((obligation) => {
          // get the rating for the obligation from ratings, with default 0. ratings is an array of objects with keys obligation and rating
          const rating = ratings.find(rating => rating.obligation == obligation)?.rating ?? 0
          const ratingClass = rating == 0 ? 'bg-orange-100' : rating == 1 ? 'bg-amber-400' : 'bg-green-400'
          return `<li class="list-disc">${obligation} <span class="inline-flex items-center gap-x-1.5 rounded-full ${ratingClass} px-1.5 py-1.5 "></span></li>`
        }).join('')

        const controls = data?.controls ?? []
        const controlsHtml = controls.map((control) => {
          return `<li class="list-disc">${control}</li>`
        }).join('')

        const gaps = data?.analysis?.gaps ?? []
        const gapsHtml = gaps.map((gap) => {
          return `<li class="list-disc">${gap}</li>`
        }).join('')

        const recommendations = data?.analysis?.recommendations ?? []
        const recommendationsHtml = recommendations.map((recommendation) => {
          return `<li class="list-disc">${recommendation}</li>`
        }).join('')

       


        tableRow.innerHTML = `
          <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0 align-top">
            ${data?.title}
          </td>
          <td class="p-4 text-sm text-gray-500 text-balance align-top min-w-96 prose prose-sm">
            ${data?.text}
          </td>
          <td class="p-4 text-sm text-gray-500 text-balance align-top min-w-96">
            <ul>${entitiesHtml}</ul>
          </td>
          <td class="p-4 text-sm text-gray-500 text-balance align-top min-w-96">
            <ul>${conditionsHtml}</ul>
          </td>
          <td class="p-4 text-sm text-gray-500 text-balance align-top min-w-96">
            <ul>${consequencesHtml}</ul>
          </td>
          <td class="p-4 text-sm text-gray-500 text-balance align-top min-w-96">
            <ul>${obligationsHtml}</ul>
          </td>
          <td class="p-4 text-sm text-gray-500 text-balance align-top min-w-96">
            <ul>${controlsHtml}</ul>
          </td>
          <td class="p-4 text-sm text-gray-500 text-balance align-top min-w-96 prose prose-sm">
            ${analysisHtml}
          </td>
          <td class="p-4 text-sm text-gray-500 text-balance align-top min-w-96">
            <ul>${gapsHtml}</ul>
          </td>
          <td class="p-4 text-sm text-gray-500 text-balance align-top min-w-96">
            <ul>${recommendationsHtml}</ul>
          </td>
        `
        this.gapsTableTarget.appendChild(tableRow)
        processedCount += 1
        progressPercent = (processedCount / totalCount) * 100
        this.testProgressLabelTarget.innerHTML = `Test progress ${progressPercent.toFixed(0)}%`
        this.testProgressBarTarget.style.width = `${progressPercent.toFixed(0)}%`
    })

    eventSource.onerror = (event) => {
        eventSource.close()
        testBtn.classList.remove('animate-pulse')
        enableElems(testBtn)
        hideElems('hidden', this.testProgressLabelTarget, this.testProgressBarTarget)
    }

    eventSource.addEventListener('streamEnd', (event) => {
        eventSource.close()
        testBtn.classList.remove('animate-pulse')
        enableElems(testBtn)
        hideElems('hidden', this.testProgressLabelTarget, this.testProgressBarTarget)
      })
    }

    showFirstPolicy(event) {
      this.btnViewPolicyTargets[0].click()
    }

    showAlertsContainer(event) {
      event.preventDefault()
      this.policiesContainerTarget.setAttribute("src", "/alerts_container")
      this.navbarItemTargets.forEach((item) => {
        if (item == event.currentTarget) {
          item.classList.add("border-violet-500")
        } else {
          item.classList.remove("border-violet-500")
        }
      })
    }

    showPoliciesContainer(event) {
      event.preventDefault()
      this.policiesContainerTarget.setAttribute("src", "/policies_container")
      this.navbarItemTargets.forEach((item) => {
        if (item == event.currentTarget) {
          item.classList.add("border-violet-500")
        } else {
          item.classList.remove("border-violet-500")
        }
      })
    }

    viewAlert(event) {
      event.preventDefault()
      this.alertDetailTarget.setAttribute("src", event.currentTarget.getAttribute("href"))
      this.alertLinkTargets.forEach((item) => {
        if (item == event.currentTarget) {
          item.classList.add("text-violet-600")
        } else {
          item.classList.remove("text-violet-600")
        }
      })
    }

    installSlack(event) {
      event.preventDefault()
      const slackUrl = event.currentTarget.getAttribute("data-slack-url")
      if (auth.currentUser) {
        auth.currentUser.getIdToken(true).then((idToken) => {
          const installUrl = `${slackUrl}/slack/install?token=${idToken}`
          window.open(installUrl, '_blank')
        }).catch((error) => {
          console.error('Error getting token:', error)
        })
      } else {
        console.error('User not authenticated')
      }
    }

}